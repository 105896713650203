import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';

const UnionPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout >
        <SEO lang="en" title={'Toki Design'} keywords={['дизайн', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'юзабилити', 'юзабилити-тестирование', 'cart-sorting']} description="Case study of UX/UI design of an application for teaching languages. We study together with native speakers. How to make app UX more intuitive for users.">
        </SEO>

        <HeroSection animationType="amazingliquid" heading="How we can help people learn new languages quickly?" desc="Creation of a service for finding people for joint language learning - Toki."/>

        <Section heading="Project">
            <Paragraph>Toki is an app which helps people find interlocutor for language that they learn. You can find native speaker to improve your language skills and help other people get better.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="toki-loading.png" alt="Загрузка приложения"/>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Prototypes" fileURL="toki-proto.png" alt="Прототипы">
            <Paragraph>Simple prototypes to quickly change the design and not think about beauty.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Welcome">
            <Paragraph>Toki greets you with some quick screens which explain main features of the application.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="toki-welcome.png" alt="Welcome screens"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Login and Sign-up">
            <Paragraph>Simple sign up flow keeps people engaged and collects important information: languages that they learning, fluent languages and about section.</Paragraph>
            <PlaceImage filename="toki-signUp.png" alt="Sign up screens"/>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Home screen">
            <Paragraph>Simple sign up flow keeps people engaged and collects important information: languages that they learning, fluent languages and about section.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="toki-home.png" alt="Home screen"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Search">
            <Paragraph>With filters on search page you an easily find right person to communicate. The app conceived as a social platform where you can find a interlocator to speak with. We intentionally set default search filters on inverted languages: so you find a person which can be your mentor and vice versa.</Paragraph>
            <PlaceImage filename="toki-search.png"  alt="Search screens"/>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Chat">
            <Paragraph>With filters on search page you an easily find right person to communicate. The app conceived as a social platform where you can find a interlocator to speak with. We intentionally set default search filters on inverted languages: so you find a person which can be your mentor and vice versa.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="toki-chats.png" alt="Chat screens"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Result">
            <Paragraph>This prototype can help people learn languages in more interesting and efficient way by finding person with common interests. This app not only helps people find native speaker for you, but also, you can become one and support interlocutor in learning new language</Paragraph>
        </Section>


    </Layout>
  ); };

export default UnionPage;
